/* eslint-disable wrap-iife */
/* eslint-disable no-use-before-define */
/**
 * The post utility
 *
 * @private
 * @function Highcharts.post
 * @param {string} url
 *        Post URL
 * @param {object} data
 *        Post data
 * @param {Highcharts.Dictionary<string>} [formAttributes]
 *        Additional attributes for the post request
 * @return {void}
 */
function strip(html) {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

if (!window['everviz']) window['everviz'] = {};
window['everviz'].strip = strip;

(function (Highcharts) {
  if (!Highcharts) return;
  let cached = {
    googleSpreadsheetKey: false,
    range: false,
    data: false
  };

  if (Highcharts.HttpUtilities) {
    Highcharts.HttpUtilities.post = function (url, data, formAttributes) {
      // create the form
      let form = Highcharts.createElement(
        'form',
        Highcharts.merge(
          {
            method: 'post',
            action: url,
            enctype: 'multipart/form-data'
          },
          formAttributes
        ),
        {
          display: 'none'
        },
        document.body
      );
      // add the data
      Highcharts.objectEach(data, function (val, name) {
        Highcharts.createElement(
          'input',
          {
            type: 'hidden',
            name: name,
            value: val
          },
          null,
          form
        );
      });

      let externalCSS = [];
      let hasCSS = false;
      if (window.HighchartsCloud && window.HighchartsCloud.externalCSS && window.HighchartsCloud.externalCSS.length) {
        externalCSS = window.HighchartsCloud.externalCSS || [];
        hasCSS = true;
      }

      if (window.evervizCSS && window.evervizCSS.externalCSS && window.evervizCSS.externalCSS.length) {
        externalCSS = externalCSS.concat(window.evervizCSS.externalCSS || []);
        hasCSS = true;
      }

      if (window.Everviz && window.Everviz.externalCSS && window.Everviz.externalCSS.length) {
        externalCSS = externalCSS.concat(window.Everviz.externalCSS || []);
        hasCSS = true;
      }

      if (hasCSS) {
        Highcharts.createElement(
          'input',
          {
            type: 'hidden',
            name: 'cssModules',
            value: JSON.stringify(externalCSS || [])
          },
          null,
          form
        );
      }

      if (formAttributes && formAttributes.uuid) {
        Highcharts.createElement(
          'input',
          {
            type: 'hidden',
            name: 'uuid',
            value: formAttributes.uuid
          },
          null,
          form
        );
      }

      // submit
      form.submit();
      // clean up
      Highcharts.discardElement(form);
    };
  }

  // Workaround for https://github.com/highcharts/highcharts/issues/16920
  // Delete this when they fix there and implement a proper solution
  Highcharts.wrap(Highcharts.Data.prototype, 'parsed', function () {
    try {
      const mapUsed = this?.chartOptions?.chart?.map;
      const joinByCode = this?.chartOptions?.series?.[0]?.joinBy?.[0];
      const map = window?.Highcharts?.maps?.[mapUsed];

      if (map && joinByCode) {
        const mapCodesBasedOnCode = (map.features ?? [])
          .map((prop) => (prop.properties?.[joinByCode] ?? '').toString())
          .filter((d) => d);

        const codeMappingIndex = this?.chartOptions?.chart?.data?.seriesMapping?.[0][joinByCode ?? 'hc-key'] ?? 0;
        for (let i = 0; i < this.columns[0].length; i++) {
          let cell = this.columns[codeMappingIndex][i];
          const stringCell = cell ?? ''.toString();
          const length = stringCell.toString().length;
          if (length < 5) {
            const invalidFipsFound =
              !mapCodesBasedOnCode.includes(stringCell) && mapCodesBasedOnCode.includes('0' + cell);
            if (invalidFipsFound) this.columns[codeMappingIndex][i] = '0' + cell;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  });

  Highcharts.wrap(Highcharts.Data.prototype, 'parseGoogleSpreadsheet', function (proceed) {
    let data = this,
      options = this.options,
      googleSpreadsheetKey = options.googleSpreadsheetKey,
      chart = this.chart,
      refreshRate = Math.min((options.dataRefreshRate || 2) * 1000, 4000);
    /**
     * Form the `values` field after range settings, unless the
     * googleSpreadsheetRange option is set.
     */
    let getRange = function () {
      if (options.googleSpreadsheetRange) {
        return options.googleSpreadsheetRange;
      }
      let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let start = (alphabet.charAt(options.startColumn || 0) || 'A') + ((options.startRow || 0) + 1);
      let end = alphabet.charAt(Highcharts.pick(options.endColumn, -1)) || 'ZZ';
      if (Highcharts.defined(options.endRow)) {
        end += options.endRow + 1;
      }
      return start + ':' + end;
    };

    /**
     * Fetch the actual spreadsheet using XMLHttpRequest.
     * @private
     */
    function fetchSheet(fn) {
      if (cached.data && !window.HighchartsCloud && !options.enablePolling) {
        // Cut down on requests to google
        if (googleSpreadsheetKey === cached.googleSpreadsheetKey && cached.range === getRange()) {
          // Errors if I dont set on a timeout, guessing a race condition

          const values = Highcharts.merge({}, cached.data);
          values.values = values.values.map(function (f) {
            return f.map(function (d) {
              return d;
            });
          });

          setTimeout(function () {
            return fn(values);
          }, 10);
          return;
        }
      }

      let url = '';
      if (!window.HighchartsCloud) {
        // We're inside the editor, 'HighchartsCloud' prop only gets set inside the publish codes
        url = [
          'https://sheets.googleapis.com/v4/spreadsheets',
          googleSpreadsheetKey,
          'values',
          getRange(),
          '?alt=json&' +
            'majorDimension=COLUMNS&' +
            'valueRenderOption=FORMATTED_VALUE&' +
            'dateTimeRenderOption=FORMATTED_STRING&' +
            'key=AIzaSyCchblEzIdk4rPKD6sbi72c4OseEdqmyPQ'
        ].join('/');
      } else {
        url = [
          'https://api.everviz.com/gsheet?googleSpreadsheetKey=',
          googleSpreadsheetKey,
          '&worksheet=',
          getRange()
        ].join('');
      }

      Highcharts.ajax({
        url: url,
        dataType: 'json',
        success: function (json) {
          let isInEvervizEditor = !window.HighchartsCloud && window.location.pathname.indexOf('/edit/');

          if (options.evervizRepublish && !isInEvervizEditor) {
            // This worksheet has recently been set to be viewable by everyone.
            // Republish this and any other chart that uses this worksheet automatically in everviz
            if (!window.everviz.publishingWorksheet) window.everviz.publishingWorksheet = {};

            if (!window.everviz.publishingWorksheet[googleSpreadsheetKey]) {
              window.everviz.publishingWorksheet[googleSpreadsheetKey] = 1;

              Highcharts.ajax({
                url: 'https://api.everviz.com/republish/googlesheet/' + googleSpreadsheetKey,
                dataType: 'json',
                success: function () {},
                error: function (xhr, text) {
                  return options.error && options.error(text, xhr);
                }
              });
            }
          }

          cached.data = Highcharts.merge({}, json);
          cached.googleSpreadsheetKey = googleSpreadsheetKey;
          cached.range = getRange();

          cached.data.values = cached.data.values.map(function (f) {
            return f.map(function (d) {
              return d;
            });
          });

          fn(json);
          if (options.enablePolling) {
            setTimeout(function () {
              fetchSheet(fn);
            }, refreshRate);
          }
        },
        error: function (xhr, text) {
          return options.error && options.error(text, xhr);
        }
      });
    }
    if (googleSpreadsheetKey) {
      delete options.googleSpreadsheetKey;
      fetchSheet(function (json) {
        let columns = json.values;
        if (!columns || columns.length === 0) {
          return false;
        }
        columns = columns.map((col, colIndex) => {
          if (colIndex === 0) return col;
          return col.map((cell, rowIndex) => {
            if (rowIndex === 0) return cell;
            if (typeof cell === 'undefined') cell = null;
            if (typeof cell === 'string' && /\d/.test(cell)) {
              const num = parseFloat(cell.replace(/[^0-9.-]/g, ''));
              if (!isNaN(num)) {
                cell = num;
              }
            }
            return cell;
          });
        });
        if (chart && chart.series) {
          chart.update({
            data: {
              columns: columns
            }
          });
        } else {
          data.columns = columns;
          data.dataFound();
        }
      });
    }
    return false;
  });

  Highcharts.addEvent(Highcharts.Chart, 'exportData', function (params) {
    const transpose = (matrix) => matrix[0].map((label, colIndex) => matrix.map((row) => row[colIndex]));
    const transposedDataRows = transpose(params.dataRows);
    let header = Object.values(transposedDataRows ?? {}).map((arr) => arr[0]);
    const targetSeriesType = params?.target?.options?.series[0]?.type;
    if (targetSeriesType === 'heatmap') header.splice(2, 2);

    if (params?.dataRows?.length && targetSeriesType !== 'packedbubble') {
      const rawColumnsLength = Object.keys(this.data.rawColumns).length;
      const leng = Object.keys(this.data.rawColumns[0]).length;
      const targetOptions = params?.target?.options;
      const useMultiLevelHeaders = targetOptions?.exporting?.useMultiLevelHeaders;
      const seriesMapping = targetOptions?.data?.seriesMapping ?? [];
      const rawColumns = params?.target?.data?.rawColumns;
      const transposedRawColumns = transpose(rawColumns);
      const seenLabels = {};

      for (const series of seriesMapping) {
        const labelValue = series.label;

        if (labelValue && !seenLabels[labelValue]) {
          const labelHeader = transposedRawColumns[0][labelValue];
          header.push(labelHeader);
          let initialIndex = 0,
            indexDecrement = 0;
          if (useMultiLevelHeaders) {
            initialIndex = 2;
            indexDecrement = 1;
          }
          for (let index = initialIndex; index < params.dataRows.length; index++) {
            const valueIndex = index - indexDecrement;
            params.dataRows[index].splice(labelValue, 0, this.data.rawColumns[labelValue][valueIndex]);
          }
          seenLabels[labelValue] = true;
        }
      }

      const reorderAndPush = (i, j) => {
        switch (j) {
          case 0:
            return this.data.rawColumns[1][i];
          case 1:
            return this.data.rawColumns[0][i];
          default:
            return this.data.rawColumns[j][i];
        }
      };

      const setDataRows = (length, callback) => {
        for (let i = 0; i <= length; i++) {
          params.dataRows[i] = [];
          for (let j = 0; j < rawColumnsLength; j++) {
            params.dataRows[i].push(callback(i, j));
          }
        }
      };

      const seriesType = params?.target?.options?.series?.[1]?.type || targetSeriesType;
      switch (seriesType) {
        case 'mappoint':
          setDataRows(rawColumnsLength, (i, j) => this.data.rawColumns[j][i]);
          break;
        case 'tilemap':
          setDataRows(rawColumnsLength, reorderAndPush);
          break;
        case 'dependencywheel':
        case 'sankey':
          setDataRows(leng, reorderAndPush);
          break;
        case 'timeline':
          setDataRows(leng, (i, j) => {
            if (seriesType === 'timeline') j++;
            return this.data.rawColumns[j]?.[i];
          });
          break;
        default:
          params.dataRows[0] = header;
          break;
      }
    }

    return params;
  });

  // This is basically just Highcharts code from their data module. Im tapping into this so
  // I can override the read function to the old way before a recent regression. Remove this whole
  // block when https://github.com/highcharts/highcharts/issues/20167 is fixed.
  Highcharts.wrap(Highcharts.Data.prototype, 'dataFound', function () {
    if (this.options.switchRowsAndColumns) {
      this.columns = this.rowsToColumns(this.columns);
    }

    // Interpret the info about series and columns
    this.getColumnDistribution();

    (this?.valueCount?.seriesBuilders ?? []).forEach((builder) => {
      builder.read = function (columns, rowIndex) {
        const builder = this,
          pointIsArray = builder.pointIsArray,
          point = pointIsArray ? [] : {};

        // Loop each reader and ask it to read its value.
        // Then, build an array or point based on the readers names.
        builder.readers.forEach((reader) => {
          const value = columns[reader.columnIndex] ? columns[reader.columnIndex][rowIndex] : undefined;

          if (pointIsArray) {
            point.push(value);
          } else {
            if (reader.configName.indexOf('.') > 0) {
              // Handle nested property names
              Highcharts.Point.prototype.setNestedProperty(point, value, reader.configName);
            } else {
              point[reader.configName] = value;
            }
          }
        });

        // The name comes from the first column (excluding the x column)
        if (typeof this.name === 'undefined' && builder.readers.length >= 2) {
          const columnIndexes = [];

          builder.readers.forEach(function (reader) {
            if (reader.configName === 'x' || reader.configName === 'name' || reader.configName === 'y') {
              if (typeof reader.columnIndex !== 'undefined') {
                columnIndexes.push(reader.columnIndex);
              }
            }
          });

          if (columnIndexes.length >= 2) {
            // Remove the first one (x col)
            columnIndexes.shift();

            // Sort the remaining
            columnIndexes.sort(function (a, b) {
              return a - b;
            });
            // Now use the lowest index as name column
            const nameColumnIndex = Highcharts.pick(columnIndexes.shift(), 0);
            this.name = columns[nameColumnIndex] ? columns[nameColumnIndex].name : '';
          }
        }

        return point;
      };
    });
    // Interpret the values into right types
    this.parseTypes();

    // Handle columns if a handleColumns callback is given
    if (this.parsed() !== false) {
      // Complete if a complete callback is given
      this.complete();
    }
  });

  function createSortIcon(type) {
    return (
      '<img style="margin-left: 0.25rem; margin-right: 0.25rem; width: 12px; height: 12px;" src="https://app.everviz.com/static/icons/' +
      type +
      '.svg"/>'
    );
  }

  const sortIcon = createSortIcon('sort');
  const sortAscIcon = createSortIcon('sort-up');
  const sortDescIcon = createSortIcon('sort-down');

  function createSortIconContainer(innerHTML, icon) {
    return '<div style="flex-direction: row; display: flex; align-items: center;">' + innerHTML + icon + '<div>';
  }

  function sortOnClick(chart) {
    const dataTableDiv = chart?.dataTableDiv;
    if (dataTableDiv) {
      const tableHeader = dataTableDiv.querySelector('.highcharts-data-table tr');

      if (tableHeader) {
        tableHeader.querySelectorAll('th').forEach((elem) => {
          if (!elem.innerHTMLCopy) return;
          // Set "sort" icon type by its highchart class
          if (elem.outerHTML.includes('highcharts-sort-descending')) {
            elem.innerHTML = createSortIconContainer(elem.innerHTMLCopy, sortDescIcon);
            elem.ariaSort = 'descending';
          } else if (elem.outerHTML.includes('highcharts-sort-ascending')) {
            elem.innerHTML = createSortIconContainer(elem.innerHTMLCopy, sortAscIcon);
            elem.ariaSort = 'ascending';
          } else {
            elem.innerHTML = createSortIconContainer(elem.innerHTMLCopy, sortIcon);
            elem.ariaSort = 'none';
          }
        });
      }
    }
  }

  // Strip data table HTML
  // Workaround for https://github.com/highcharts/highcharts/issues/16536
  Highcharts.addEvent(Highcharts.Chart, 'afterViewData', function () {
    const customTextFont = this.options.chart?.style?.fontFamily;
    // Strip table caption
    const tableCaptionElement = document.querySelector('.highcharts-table-caption');

    if (tableCaptionElement) {
      tableCaptionElement.innerText = strip(this.options.exporting.tableCaption || this.options.title.text);
      tableCaptionElement.style.fontFamily = customTextFont;
    }

    // Strip table headers. Not exposed through API, override DOM
    document.querySelectorAll('.highcharts-data-table .highcharts-text').forEach((el) => {
      el.innerText = strip(el.innerText);
      el.style.fontFamily = customTextFont;
    });

    // Strip table numeric data/apply decimal point & thousands seperator
    const thousandsRegex = /\B(?=(\d{3})+(?!\d))/g;

    document.querySelectorAll('.highcharts-data-table .highcharts-number').forEach((el) => {
      el.innerText = strip(el.innerText)
        .replace('.', this.options.lang.decimalPoint)
        .replace(thousandsRegex, this.options.lang.thousandsSep);
      el.style.fontFamily = customTextFont;
    });

    // Add sort icons and accessibility to chart data preview
    const dataTableDiv = this.dataTableDiv;
    if (dataTableDiv) {
      const tableHeader = dataTableDiv.querySelector('.highcharts-data-table tr');

      if (tableHeader) {
        tableHeader.addEventListener('click', (current, _) => sortOnClick(this));

        // Append a "sort" icon to the element, and store a copy of the original innerHTML value
        tableHeader.querySelectorAll('th').forEach((elem) => {
          elem.innerHTMLCopy = elem.innerHTML;
          elem.innerHTML = createSortIconContainer(elem.innerHTML, sortIcon);
          elem.title = elem.innerText;
        });
      }
    }
  });

  // Override for Highcharts a11y proxy button issue.
  // ToDo: Extend to also reposition Options. Only repositioning legend.
  // See: https://github.com/highcharts/highcharts/issues/17642
  let topOffset;
  let proxyButtons = '.highcharts-a11y-proxy-element';
  Highcharts.addEvent(Highcharts.Chart, 'render', () => {
    if (!topOffset) {
      topOffset = document.querySelector(proxyButtons)?.style?.top;
    } else if (topOffset) {
      // Need to delay as Highcharts override our changes without it.
      setTimeout(() => {
        document.querySelectorAll(proxyButtons).forEach((button) => {
          button.style.top = parseInt(button.style.top) > parseInt(topOffset) ? button.style.top : topOffset;
        });
      }, 1000);
    }
  });

  // Apply a theme like Highcharts v10
  // https://www.highcharts.com/samples/highcharts/members/theme-v10
  // Hopefully fixes visual regressions users are seeing
  Highcharts.setOptions({
    chart: {
      zooming: {
        // By default on with Stock, introduced 11.1.0
        // Traps mouse scroll on all published charts
        mouseWheel: false
      }
    },
    title: {
      style: {
        fontWeight: 'normal',
        fontSize: '18px'
      }
    },
    plotOptions: {
      area: {
        lineWidth: 2
      },
      column: {
        borderRadius: 0
      },
      pie: {
        borderRadius: 0,
        dataLabels: {
          connectorShape: 'fixedOffset'
        }
      },
      line: {
        lineWidth: 2
      },
      spline: {
        lineWidth: 2
      }
    },
    tooltip: {
      borderWidth: 1
    },
    legend: {
      itemStyle: {
        fontWeight: 'bold'
      }
    },
    xAxis: {
      labels: {
        distance: 8,
        style: {
          color: '#666666',
          fontSize: '11px'
        }
      }
    },
    yAxis: {
      labels: {
        distance: 8,
        style: {
          color: '#666666',
          fontSize: '11px'
        }
      }
    },
    scrollbar: {
      barBorderRadius: 0,
      barBorderWidth: 1,
      buttonsEnabled: true,
      height: 14,
      margin: 0,
      rifleColor: '#333',
      trackBackgroundColor: '#f2f2f2',
      trackBorderRadius: 0
    }
  });

  // TODO: This whole if statement needs to be removed in future. The logic has been moved to highcharts-resize.js.
  // Only kept here for now so it doesnt break old publish charts that rely on it. We only need the highcharts-resize
  // file linked once on the users page for all embed charts on the page to pick it up so keep this section here running
  // in the interim and remove when enough time has passed that the resize file is more than likely on the users site.
  if (window.HighchartsCloud && !window.inEverviz) {
    Highcharts.addEvent(Highcharts.Chart, 'load', function () {
      // v6 of publish codes has new resizing logic in it. Dont run this function then as it'll just run the
      // one in the highcharts-resize file instead.
      this.hasNewFontSizing = (window?.HighchartsCloud?.versions ?? []).includes(6);
      if (this.hasNewFontSizing) return;

      const templateMinPlotHeight = {
        packedbubble: 400
      };
      const hasHeight = this.userOptions && this.userOptions.chart && this.userOptions.chart.height;
      this.evervizDynamicHeight = !hasHeight;
      this.evervizScaleToFit = this.options.everviz?.animation?.scaleToFit;
      this.evervizMinPlotHeight = templateMinPlotHeight[this.userOptions?.chart?.type] || 300;
    });

    Highcharts.addEvent(Highcharts.Chart, 'render', function () {
      // Dont want to run the map dynamic scaling on stub or if the user
      // has the new resizing logic that was added in v6 of the embed codes

      if (this.hasNewFontSizing) return;
      if (this.evervizScaleToFit && this.userOptions?.everviz?.stub) return;

      // User has set explicit height
      if (!this.evervizDynamicHeight) return;

      let newHeight = this.evervizScaleToFit ? getHeightForScaled.call(this) : getHeightForDynamic.call(this);

      if (this.previousHeight === newHeight) return;

      this.previousHeight = newHeight;

      let updateConfig = {};
      if (this.evervizScaleToFit) {
        updateConfig = {
          margin: [5, 5, 5, 5]
        };

        const { offsetHeight } = getDecorationHeight.call(this);
        if (offsetHeight) {
          // Add some small margin to prevent map from touching text directly
          const PAD = 25;
          offsetMap((offsetHeight + PAD) / 2);
        }
      }

      this.update({
        chart: {
          height: newHeight,
          ...updateConfig
        }
      });

      function getHeightForDynamic() {
        return this.fullscreen?.isOpen ? undefined : this.chartHeight + (this.evervizMinPlotHeight - this.plotHeight);
      }

      function getHeightForScaled() {
        const map = this.container.querySelector('.highcharts-series-group');
        const mapBox = map.getBBox();
        const { decorationHeight } = getDecorationHeight.call(this);
        const heightFromWidth = mapBox.height / mapBox.width;

        return parseInt(this.chartWidth * heightFromWidth, 10) + decorationHeight;
      }

      function getElementHeight(element) {
        let dimensions;
        if (element?.getBBox) dimensions = element.getBBox();
        else if (element?.getBoundingClientRect) dimensions = element.getBoundingClientRect();
        return dimensions?.width && dimensions?.height ? dimensions.height : 0;
      }

      function getDecorationHeight() {
        const titleHeight = getElementHeight(this.title?.element);
        const subtitleHeight = getElementHeight(this.subtitle?.element);
        const legendHeight = this.legend?.box?.getBBox().height ?? 0;

        return {
          decorationHeight: titleHeight + subtitleHeight + legendHeight,
          offsetHeight: titleHeight + subtitleHeight
        };
      }

      function offsetMap(y) {
        const map = this.container.querySelector('.highcharts-series-group');
        map.setAttribute('transform', `translate(0, ${y})`);
      }
    });
  }
})(window.Highcharts);
