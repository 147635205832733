if (!window['everviz']) window['everviz'] = {};

window['everviz'].tooltipFormatter = function (tooltip) {
  if (this.point && this.point.formatPrefix === 'node') {
    let series = this.series,
      tooltipOptions = series.tooltipOptions;
    return (tooltipOptions[(this.point.formatPrefix || 'point') + 'Formatter'] || this.point.tooltipFormatter).call(
      this.point,
      tooltipOptions[(this.point.formatPrefix || 'point') + 'Format'] || ''
    );
  }

  const chart = tooltip.chart;
  const evervizOptions = chart.userOptions.everviz;
  const tooltipEvervizOptions = Highcharts.merge(
    {
      vars: {}
    },
    evervizOptions.tooltip.options || {}
  );

  const options = tooltip.options;

  const getPredefinedVar = (evervizOptions, options, type) => {
    if (evervizOptions.tooltip.options && evervizOptions.tooltip.options.vars[type])
      return evervizOptions.tooltip.options.vars[type];
    return options[type] ?? '';
  };

  const parseValue = (val, option) => {
    let parsedVal =
      tooltipEvervizOptions.vars[val] ??
      (option.series && option.series.tooltipOptions && option.series.tooltipOptions[val]) ??
      option[val] ??
      val;

    let series = option.point.series,
      tooltipOptions = series.tooltipOptions,
      valueDecimals = Highcharts.pick(tooltipOptions.valueDecimals, ''),
      valuePrefix = tooltipOptions.valuePrefix || '',
      valueSuffix = tooltipOptions.valueSuffix || '';

    let xDateFormat = tooltipOptions.xDateFormat,
      xAxis = series.xAxis,
      isDateTime =
        xAxis && (xAxis.options.type === 'datetime' || xAxis.type === 'datetime') && Highcharts.isNumber(option.key),
      dateTime = xAxis && xAxis.dateTime;

    if (isDateTime && !xDateFormat && dateTime) {
      xDateFormat = dateTime.getXDateFormat(option.key, tooltipOptions.dateTimeLabelFormats);
    }

    if (isDateTime && xDateFormat) {
      ((option.point && option.point.tooltipDateKeys) || ['key']).forEach(function (key) {
        parsedVal = parsedVal.replace('{point.' + key + '}', '{point.' + key + ':' + xDateFormat + '}');
      });
    }

    (series.pointArrayMap || ['y']).forEach(function (key) {
      key = '{point.' + key;
      if (valuePrefix || valueSuffix) {
        parsedVal = parsedVal.replace(RegExp(key + '}', 'g'), valuePrefix + key + '}' + valueSuffix);
      }
      parsedVal = parsedVal.replace(RegExp(key + '}', 'g'), key + ':,.' + valueDecimals + 'f}');
    });

    return Highcharts.format(parsedVal, option, series.chart);
  };

  const getPointValue = (point) => parseValue('pointFormat', point);
  const getPointsValue = () =>
    this.points.map((point) => {
      setPercentOptions({ ...this, ...point });
      return getPointValue({ ...this, ...point });
    });

  const setPercentOptions = (point) => {
    if (!point.percentage) return;

    let percentageStr = point.percentage.toString();

    if (percentageStr.includes('%')) {
      percentageStr = percentageStr.replace('%', '');
      if (percentageStr.includes(',')) {
        percentageStr = percentageStr.replace(',', '.');
      }
      point.percentage = parseFloat(percentageStr);
    }

    [0, 1, 2].forEach((i) => (point['percentage' + i] = point.percentage.toFixed(i)));
  };

  this.headerFormat = getPredefinedVar(evervizOptions, options, 'headerFormat');
  this.pointFormat = getPredefinedVar(evervizOptions, options, 'pointFormat');
  this.footerFormat = getPredefinedVar(evervizOptions, options, 'footerFormat');

  if (this.point) {
    this.point.formattedValue = this.point.value ?? this.y;
    if (options.valueSuffix) this.point.formattedValue = this.point.formattedValue + options.valueSuffix;
    if (options.valuePrefix) this.point.formattedValue = options.valuePrefix + this.point.formattedValue;
  }

  if (tooltip.shared && this.points) {
    this.points[0].point.key = this.points[0].key;
    const returnVal = [parseValue('headerFormat', { ...this, ...this.points[0] })];
    chart.evervizTooltipOptions = Highcharts.merge(this, this.points[0].point);
    returnVal.push(...getPointsValue());
    return returnVal;
  }

  chart.evervizTooltipOptions = this;

  setPercentOptions(this);

  if (
    this.point.index &&
    chart.series &&
    chart.series[0] &&
    chart.series[0].data &&
    chart.series[0].data[this.point.index]
  ) {
    const dataPointOptions = chart.series[0].data[this.point.index];
    if (!this.point.label) this.point.label = dataPointOptions.label;
    if (!this.point.extra) this.point.extra = dataPointOptions.extra;
  }

  return ['headerFormat', 'pointFormat'].map((key) => {
    this.point.key = this.key;
    return parseValue(key, this);
  });
};
