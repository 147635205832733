import actionTypes from '../../../redux/actions/action-types';

export const getParentTeamMembersAction = (data) => ({
  type: actionTypes.teamMembersPage.getAllMembers,
  data
});

export const getMembersAction = (data) => ({
  type: actionTypes.teamMembersPage.getMembers,
  data
});

export const getDivisionMembersAction = (data) => ({
  type: actionTypes.teamMembersPage.getDivisionMembers,
  data
});

export const updateUsersGroupAction = (data) => ({
  type: actionTypes.teamMembersPage.updateUsersGroup,
  data
});

export const updateDivisionMembersAction = (data) => ({
  type: actionTypes.teamMembersPage.updateDivisionMembers,
  data
});

export const deleteTeamUsersAction = (data) => ({
  type: actionTypes.teamMembersPage.deleteTeamUsers,
  data
});

export const setTeamMembersAction = (data) => ({
  type: actionTypes.teamMembersPage.set,
  data
});

export const createDivisionAction = (data) => ({
  type: actionTypes.teamMembersPage.createDivision,
  data
});

export const updateDivisionNameAction = (data) => ({
  type: actionTypes.teamMembersPage.updateDivisionName,
  data
});

export const deleteDivisionAction = (data) => ({
  type: actionTypes.teamMembersPage.deleteDivision,
  data
});
