import { put, takeLatest, all, call } from 'redux-saga/effects';
import { setTeamMembersAction, getMembersAction, getDivisionMembersAction } from '../actions/teamMembersAction';
import actionTypes from '../../../redux/actions/action-types';
import { snackBar } from 'editor/editors/highed.init';
import {
  getTeamUsersWithTeamid,
  delTeamUserBatchUsingTeamid,
  postTeamRoleUserUsingTeamidAndRoleidAndUserid,
  postDivisionUserUsingDivisionidAndUserid,
  getDivisionTeamAllWithDivisionidAndTeamid,
  postDivisionUsingDivisionid,
  postDivision,
  getTeamDivisionUsersWithTeamidAndDivisionid
} from '../../../api/cloudApiGenerated';
import { setAction } from 'redux/actions/profile';
import { setDivision } from 'utils/profileHelper';

export function* getAllMembers(params) {
  setTeamMembersAction({
    parentTeamMembers: {
      loading: true
    }
  });

  try {
    let { team, division } = params.data;
    const membersData = yield call(getTeamDivisionUsersWithTeamidAndDivisionid, team.id, division.id);
    yield put(
      setTeamMembersAction({
        parentTeamMembers: {
          data: membersData.length ? membersData : [],
          loading: false,
          error: null
        }
      })
    );
  } catch (e) {
    yield put(
      setTeamMembersAction({
        parentTeamMembers: {
          data: [],
          loading: false,
          error: e.response.message.join(' ')
        }
      })
    );
  }
}

export function* getMembers(params) {
  let { team, query, page, pageSize } = params.data;

  setTeamMembersAction({
    members: {
      loading: true
    }
  });

  try {
    let {
      data: members,
      pageCount,
      page: updatedPage
    } = yield call(getTeamUsersWithTeamid, team.id, query, page, pageSize);

    yield put(
      setTeamMembersAction({
        allMembers: {
          data: members ?? [],
          error: null
        },
        members: {
          data: members ?? [],
          page: updatedPage,
          pageCount,
          pageSize,
          loading: false,
          error: null
        }
      })
    );
  } catch (e) {
    yield put(
      setTeamMembersAction({
        members: {
          data: [],
          loading: false,
          error: e.response.message.join(' ')
        }
      })
    );
  }
}

export function* getDivisionMembers(params) {
  const { team, query, pageSize, page, division } = params.data;

  setTeamMembersAction({
    subteamMembers: {
      loading: true
    }
  });

  try {
    const {
      data: subteamMembers,
      pageCount,
      page: updatedPage
    } = yield call(getDivisionTeamAllWithDivisionidAndTeamid, division, team.id, query, page, pageSize);

    const formattedSubteamMembers = subteamMembers.map(({ user_id: id, username, email, inDivision, group_id }) => ({
      id,
      username,
      email,
      inDivision,
      group_id
    }));

    yield put(
      setTeamMembersAction({
        subteamMembers: {
          data: formattedSubteamMembers,
          page: updatedPage,
          pageCount,
          pageSize,
          loading: false,
          error: null
        }
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      setTeamMembersAction({
        subteamMembers: {
          data: [],
          loading: false,
          error: error.response.message.join(' ')
        }
      })
    );
  }
}

export function* updateUsersGroup(params) {
  let { team, divisions, rows, activeDivisionId, membersQuery, page, pageSize } = params.data;

  try {
    let usersTeamGroupsToUpdate = [];
    rows.forEach((row) => {
      if (row) {
        usersTeamGroupsToUpdate.push(
          call(postTeamRoleUserUsingTeamidAndRoleidAndUserid, team.id, row.group_id, row.id)
        );
      }
    });

    yield all(usersTeamGroupsToUpdate);

    if (typeof activeDivisionId === 'number' || typeof activeDivisionId === 'string') {
      yield put(
        getDivisionMembersAction({ team, divisions, division: activeDivisionId, query: membersQuery, page, pageSize })
      );
    } else {
      yield put(getMembersAction({ team, query: membersQuery, page, pageSize }));
    }

    if (usersTeamGroupsToUpdate.length > 0) {
      snackBar('Members roles updated');
    } else {
      snackBar('Member role updated');
    }
  } catch (error) {
    yield put(
      setTeamMembersAction({
        subteamMembers: {
          data: [],
          error: 'setting access group of member: ' + error.message.join(' ')
        }
      })
    );
  }
}

export function* createDivision(params) {
  const { divisions, subteamName, teamId, selectedMembers, callback } = params.data;

  try {
    const data = yield call(postDivision, { name: subteamName, team_id: teamId, selectedMembers });
    const newSubteamId = data?.ids[0];
    const formattedDivisions = [...divisions, { id: newSubteamId, name: subteamName }];

    yield put(
      setTeamMembersAction({
        activeDivision: { val: newSubteamId, label: subteamName },
        isSubteamCreateModalOpen: false
      })
    );
    yield put(setAction({ divisions: formattedDivisions }));

    setDivision({ id: newSubteamId, name: subteamName });
    callback();
    snackBar('Subteam created');
  } catch (error) {
    console.error(error);
  }
}

export function* updateDivisionMembers(params) {
  const { team, divisions, membersQuery, page, pageSize, subteamMembers, validSubteamMembers, activeDivision } =
    params.data;
  const promises = [];

  subteamMembers.forEach((subteamMember) => {
    const data = {
      inDivision: validSubteamMembers.find((validSubteamMember) => validSubteamMember.id === subteamMember.id) ? 1 : 0
    };
    promises.push(call(postDivisionUserUsingDivisionidAndUserid, activeDivision.value, subteamMember.id, data));
  });

  try {
    yield all(promises);
    yield put(
      getDivisionMembersAction({ team, divisions, division: activeDivision.value, query: membersQuery, page, pageSize })
    );
    yield put(
      setTeamMembersAction({
        isUsersSubteamAddModalOpen: false,
        isUserSubteamRemoveModalOpen: false,
        selectedUser: null
      })
    );
    snackBar('Updated subteam');
  } catch (error) {
    console.error(error);
  }
}

export function* updateDivisionName(params) {
  const { divisions, divisionId, divisionName } = params.data;

  try {
    yield call(postDivisionUsingDivisionid, divisionId, { name: divisionName });

    const formattedDivisions = divisions.map((division) =>
      division.id === divisionId ? { id: divisionId, name: divisionName } : division
    );

    yield put(
      setTeamMembersAction({
        activeDivision: { val: divisionId, label: divisionName },
        editDivisionModalOpen: false
      })
    );
    yield put(setAction({ divisions: formattedDivisions }));

    setDivision({ id: divisionId, name: divisionName });
    snackBar('Updated subteam name');
  } catch (error) {
    console.error(error);
  }
}

export function* deleteDivision(params) {
  const { divisions, divisionId } = params.data;

  try {
    yield call(postDivisionUsingDivisionid, divisionId, { deleted: true });
    yield put(
      setTeamMembersAction({
        activeDivision: { val: null, label: 'All subteams' },
        editDivisionModalOpen: false
      })
    );
    yield put(setAction({ divisions: divisions.filter((division) => division.id !== divisionId) }));
    setDivision({ id: null, name: 'All subteams' });
    snackBar('Subteam deleted');
  } catch (error) {
    console.error(error);
  }
}

export function* deleteTeamUsers(params) {
  let { team, divisions, users, activeDivisionId, membersQuery, page, pageSize } = params.data;

  let ids = [];
  const owner_user = team.owner_user;

  users.forEach((user) => {
    if (user && user.id !== owner_user) {
      ids.push(user.id);
    }
  });

  if (ids.length === 0) return;

  try {
    yield call(delTeamUserBatchUsingTeamid, team.id, {
      users: ids.join(',')
    });

    if (typeof activeDivisionId === 'number') {
      yield put(
        getDivisionMembersAction({ team, divisions, division: activeDivisionId, query: membersQuery, page, pageSize })
      );
    } else {
      yield put(getMembersAction({ team, query: membersQuery, page, pageSize }));
    }

    yield put(setTeamMembersAction({ isUsersDeleteModalOpen: false, selectedUser: null }));
    snackBar('Member deleted');
  } catch (error) {
    console.error(error);
  }
}

/** Watch functions */
export function* watchGetAllMembers() {
  yield takeLatest(actionTypes.teamMembersPage.getAllMembers, getAllMembers);
}
export function* watchGetMembers() {
  yield takeLatest(actionTypes.teamMembersPage.getMembers, getMembers);
}
export function* watchGetDivisionMembers() {
  yield takeLatest(actionTypes.teamMembersPage.getDivisionMembers, getDivisionMembers);
}
export function* watchUpdateUsersGroup() {
  yield takeLatest(actionTypes.teamMembersPage.updateUsersGroup, updateUsersGroup);
}
export function* watchUpdateDivisionMembers() {
  yield takeLatest(actionTypes.teamMembersPage.updateDivisionMembers, updateDivisionMembers);
}
export function* watchDeleteTeamUsers() {
  yield takeLatest(actionTypes.teamMembersPage.deleteTeamUsers, deleteTeamUsers);
}
export function* watchCreateDivision() {
  yield takeLatest(actionTypes.teamMembersPage.createDivision, createDivision);
}
export function* watchUpdateDivisionName() {
  yield takeLatest(actionTypes.teamMembersPage.updateDivisionName, updateDivisionName);
}
export function* watchDeleteDivision() {
  yield takeLatest(actionTypes.teamMembersPage.deleteDivision, deleteDivision);
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchGetAllMembers(),
    watchGetMembers(),
    watchGetDivisionMembers(),
    watchUpdateUsersGroup(),
    watchUpdateDivisionMembers(),
    watchDeleteTeamUsers(),
    watchCreateDivision(),
    watchUpdateDivisionName(),
    watchDeleteDivision()
  ]);
}
