import { formatDistanceToNow } from 'date-fns';
import { saveThemeAction } from 'pages/ThemeEditorPage/actions/themeEditor';
import qs from 'qs';
import React from 'react';
import store from 'redux/store';
import { merge } from 'lodash';
import { publishChartAction, saveChartAction } from '../../pages/ChartEditorPage/actions/chartEditor';
import { publishLayoutAction, saveLayoutAction } from '../../pages/LayoutEditorPage/actions/layoutEditor';
import { publishTableAction, saveTableAction } from '../../pages/TableEditorPage/actions/tableEditor';
import { generateQueryString } from '../utils/editorHelper';
import { snackBar } from 'editor/editors/highed.init';

const mimeTypes = {
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf'
};

const types = {
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'image/svg+xml': 'svg'
};

export const fpsOptions = [
  {
    value: 50,
    label: '50P (Pal)'
  },
  {
    value: '60i',
    label: '60i (NTSC)'
  },
  {
    value: 60,
    label: '60FPS (Web/Mobile)'
  },
  {
    value: 25,
    label: '25FPS (Web/Mobile)'
  }
];

export const fileOptions = [
  {
    value: 'html',
    label: 'HTML'
  },
  {
    value: 'json',
    label: 'JSON'
  }
];

const getFormattedFileName = (name) => {
  let words = name ? name.split(' ') : [];
  words = words.length > 3 ? words.slice(0, 3) : words;
  return words.join('-');
};

const publishMap = {
  chart: publishChartAction,
  layout: publishLayoutAction,
  table: publishTableAction
};

export const downloadPackageProject = (project_uuid, uuid, key, exportedBy) => {
  const { cssModules, aggregatedOptions: options } = store.getState().projectConfig;
  const { stockSymbol } = store.getState().packagesPage;
  const { team } = store.getState().profile;
  const { themeData } = store.getState().packagesPage;

  downloadImage('chart', types[key], {
    options: options ? JSON.stringify(options) : null,
    themeData: themeData ? JSON.stringify(themeData) : null,
    type: key,
    constr: 'chart',
    projectType: 'package',
    cssModules: JSON.stringify(cssModules ?? []),
    project_uuid,
    uuid,
    teamId: team.id,
    exportedBy,
    stockSymbol
  });
};

export const downloadLocationMapImage = async (key, options, uuid, cssModules, teamId) => {
  const name = options?.title?.text ?? 'chart';
  downloadImage(name, types[key], {
    options: JSON.stringify(options),
    type: key,
    constr: 'locationMap',
    projectType: 'locationMap',
    cssModules: JSON.stringify(cssModules || []),
    pluginConfig: JSON.stringify({}),
    uuid,
    teamId
  });
};

export const downloadChartImage = async (props) => {
  const { constr, type, uuid, teamId, chartId, projectName, shouldCallRecentExport, exportedBy } = props;
  const { packageProject } = store.getState().packagesPage;

  if (packageProject.project_uuid && packageProject.uuid) {
    downloadPackageProject(packageProject.project_uuid, packageProject.uuid, type, exportedBy);
    return;
  }

  let chartType = constr;
  if (chartType === 'Chart') chartType = 'chart';

  const fileName = getFormattedFileName(projectName);
  const svgMarkup = Highcharts.charts[Highcharts.charts.length - 1].getSVG();

  downloadImage(
    fileName,
    types[type],
    {
      filename: fileName,
      exportedBy,
      svg: svgMarkup,
      type,
      constr: chartType,
      projectType: 'chart',
      cssModules: JSON.stringify(window?.Everviz?.externalCSS || []),
      teamId: teamId,
      uuid,
      chartId,
      scale: 1
    },
    shouldCallRecentExport
  );
};

export const downloadTableImage = async (props, imageType = 'png') => {
  const {
    aggregatedOptions,
    type,
    exportedBy,
    cssModules,
    dataOptions,
    uuid,
    teamId,
    tableId,
    projectName,
    shouldCallRecentExport
  } = props;

  let options = merge({}, aggregatedOptions);
  let aggOptions = {
    options,
    data: {
      value: dataOptions
    },
    cssModules: cssModules || []
  };

  const fileName = getFormattedFileName(projectName);

  downloadImage(
    fileName,
    imageType,
    {
      filename: fileName,
      options: JSON.stringify(aggOptions),
      type: mimeTypes[imageType],
      exportedBy,
      constr: 'table',
      projectType: type,
      cssModules: JSON.stringify(cssModules || []),
      pluginConfig: JSON.stringify({}),
      teamId: teamId,
      chartId: tableId,
      uuid
    },
    shouldCallRecentExport
  );
};

const downloadImage = async (name, imageType, body, shouldCallRecentExport) => {
  const { teamId, chartId } = body;
  const { hostname } = window.hcconfig.backend;
  const baseUrl = `${hostname}/team/${teamId}`;
  const exportUrl = chartId ? `${baseUrl}/chart/${chartId}/export` : `${baseUrl}/export`;

  const response = await fetch(exportUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    const imageBlog = await response.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = `${name}.${imageType}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    const errorMessage = await response.text();
    snackBar(errorMessage);
  }

  if (shouldCallRecentExport) shouldCallRecentExport(true);
};

export const getPublishStatus = (published, saved, changeMade, lastPublishTime, lastSavedTime) => {
  const lastPublished = lastPublishTime && new Date(lastPublishTime);
  const lastSaved = lastSavedTime && new Date(lastSavedTime);

  if (lastPublished && lastSaved && lastSaved > lastPublished) {
    return {
      buttonText: 'Republish',
      status: 'Published',
      text: 'Project is published with saved changes. Republish it to show saved changes in link and embed codes.'
    };
  }

  if (published && changeMade) {
    return {
      buttonText: 'Republish',
      status: 'Published',
      text: 'Project is published, but has new changes. Republish it to show changes in link and embed codes.'
    };
  }
  if (published && !changeMade) {
    return {
      buttonText: 'Published',
      status: 'Published',
      text: 'Project is published and saved. Project link and embed codes are public.',
      light: true
    };
  }
  if (saved) {
    return {
      buttonText: 'Publish',
      status: 'Unpublished',
      text: 'Project is saved. Publish to generate a public link or embed code.'
    };
  }
  return {
    buttonText: 'Publish',
    status: 'Unsaved',
    text: 'Save your progress so far or publish to generate a project link or embed code.'
  };
};

export const onSaveAndPublish = (type, dispatch) => {
  onSave(type, false, dispatch, () => {
    onPublish(type, dispatch);
  });
};

export const onPublish = (type, dispatch) => {
  dispatch(
    publishMap[type]({
      inspired: false
    })
  );
};

export const onSave = (type, isThemeEditor, dispatch, cb) => {
  const saveMap = {
    chart: saveChartAction,
    layout: saveLayoutAction,
    table: saveTableAction
  };

  let save = isThemeEditor ? saveThemeAction : saveMap[type];

  dispatch(
    save({
      reload: false,
      hideNotification: true,
      callback: (data) => {
        if (type === 'layout' || type === 'table') {
          const typeId = type === 'layout' ? 'story_id' : 'table_id';

          const { showWizard, tab, urlParam } = store.getState().projectConfig;
          const { isMap } = store.getState().chartEditorPage;
          const queryTab = showWizard ? urlParam : tab;

          const queryString = generateQueryString(qs, queryTab, type, showWizard, isMap);
          window.history.pushState(null, null, `/${type}/edit/${data[typeId]}${queryString}`);
        }
        if (cb) {
          // eslint-disable-next-line callback-return
          cb();
        }
      }
    })
  );
};

export const getStatus = (lastPublishTime, lastSaved) => {
  if (lastPublishTime) {
    const lastPublish = new Date(lastPublishTime);
    return <span title={lastPublish.toUTCString()}>Last published: {formatDistanceToNow(lastPublish)} ago</span>;
  }

  if (lastSaved) {
    const lastSavedTime = new Date(lastSaved);
    return <span title={lastSavedTime.toUTCString()}>Last saved: {formatDistanceToNow(lastSavedTime)} ago</span>;
  }

  return '';
};
