import PublishExport from 'shared/editor/generic/PublishExport';
import PackageCustomize from 'shared/editor/generic/PackageCustomize';
import { PanelOptionProps } from 'shared/editor/generic/OptionsPanel';

const options: Array<PanelOptionProps> = [
  {
    id: 'customize',
    name: 'Customize',
    element: PackageCustomize as unknown as React.ReactNode,
    permission: 'customize'
  },
  {
    id: 'publish',
    name: 'Publish & Export',
    element: PublishExport as unknown as React.ReactNode,
    permission: 'publish'
  }
];

export default options;
