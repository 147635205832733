/*
    en Language Pack for the Highcharts Editor
    This file was generated by tools/gen.localization.js from
    en.json, Thu Mar 15 2018 10:04:15 GMT+0100 (CET)
*/

//Install "en" translations
//highed.installLanguage({
export default {
  language: 'en',
  entries: {
    //Toolbox
    'option.text.font': 'Global settings',
    'option.text.textStyle': 'Text style',
    'option.text.canvas': 'Canvas',

    //Font:
    'option.text.font.globalFont': 'Global font',
    'option.text.font.titleFont': 'Title font',

    //Color palette:
    'option.text.colorPalette': 'Color palette',

    //Table specific
    'option.text.tableSpecific': 'Table specific',
    'option.text.header.style.backgroundColor': 'Header color',
    'option.text.table.style.backgroundColor': 'Background color',
    'option.text.rows.stripesColor': 'Stripes color',
    'option.text.rows.textColor': 'Text color',

    //Map specific
    'option.text.mapSpecific': 'Color palette',
    'option.text.plotOptions.map.nullColor': 'Base color',

    //Text style:
    'option.text.textStyle.globalTextColor': 'Global text color',
    'option.text.textStyle.titleTextColor': 'Title text color',
    'option.text.textStyle.title': 'Title',
    'option.text.textStyle.subtitle': 'Subtitle',
    'option.text.textStyle.caption': 'Caption',
    'option.text.textStyle.searchable.labelText': 'Search in table',
    'option.text.textStyle.legendPlacement': 'Legend placement',
    'option.text.textStyle.xAxis.title': 'X-axis',
    'option.text.textStyle.yAxis.title': 'Y-axis',

    //Canvas:
    'option.text.canvas.backgroundColor': 'Background color',
    'option.text.canvas.borderColor': 'Border color',
    'option.text.canvas.border': 'Border',
    'option.text.canvas.padding': 'Padding',

    //Axis
    'option.cat.axes': 'Axes',
    'option.subcat.xAxis': 'Horizontal axis',
    'option.subcat.yAxis': 'Vertical axis',
    'option.text.xAxis.enabled': 'Visibility',
    'option.text.yAxis.enabled': 'Visibility',

    'option.text.xAxis.lineWidth': 'Line Width',
    'option.text.xAxis.lineColor': 'Line Color',
    'option.text.xAxis.tickWidth': 'Show Ticks',
    'option.text.xAxis.labels.style': 'Label Style',
    'option.text.xAxis.gridLineDashStyle': 'Gridline Style',
    'option.text.xAxis.gridLineWidth': 'Gridline Width',

    'option.text.yAxis.lineWidth': 'Line Width',
    'option.text.yAxis.lineColor': 'Line Color',
    'option.text.yAxis.tickWidth': 'Show Ticks',

    //Export menu
    'option.text.exporting': 'Export menu',
    'option.text.exporting.buttons.contextButton.enabled': 'Visibility',
    'option.text.exporting.buttons.contextButton.text': 'Button text',
    'option.text.exporting.buttons.contextButton.symbol': 'Button icon',
    'option.text.exporting.style.backgroundColor': 'Background color',
    'option.text.exporting.style.color': 'Text color',
    'option.text.exporting.localization': 'Text descriptions',
    'option.text.exporting.lang.contextButtonTitle': 'Context button tooltip',
    'option.text.exporting.lang.downloadPNG': 'Download PNG image',
    'option.text.exporting.lang.downloadJPEG': 'Download JPEG image',
    'option.text.exporting.lang.downloadPDF': 'Download PDF document',
    'option.text.exporting.lang.downloadCSV': 'Download CSV',
    'option.text.exporting.lang.downloadXLS': 'Download XLS',
    'option.text.lang.viewFullscreen': 'View in full screen',
    'option.text.lang.downloadPNG': 'Download PNG image',
    'option.text.lang.downloadJPEG': 'Download JPEG image',
    'option.text.lang.downloadPDF': 'Download PDF document',
    'option.text.lang.downloadSVG': 'Download SVG vector image',
    'option.text.lang.downloadCSV': 'Download CSV',
    'option.text.lang.downloadXLS': 'Download XLS',
    'option.text.lang.viewData': 'View data table',
    'option.text.lang.contextButtonTitle': 'Context button tooltip',
    'option.text.lang.printChart': 'Print chart',

    //Source
    'option.text.credits.text': 'Source',
    'option.text.yAxis.labels.style': 'Label Style',
    'option.text.yAxis.gridLineDashStyle': 'Gridline Style',
    'option.text.yAxis.gridLineWidth': 'Gridline Width',

    'option.text.everviz.text.dynamicFonts': 'Enable dynamic fonts',

    //Credits
    'option.text.credits': 'Credits',
    'option.text.credits.enabled': 'Visibility'
  }
};

//});
