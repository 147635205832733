import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Modal from 'shared/modal/Modal';
import {
  getParentTeamMembersAction,
  setTeamMembersAction,
  updateDivisionMembersAction
} from '../actions/teamMembersAction';
import { RootState } from 'redux/store';
import CheckboxInput from 'shared/inputs/CheckboxInput';
import InputSearch from 'shared/inputs/InputSearch';

interface TeamMember {
  id: number;
  email: string;
  username: string;
  first_seen: string;
  last_seen: string;
  group_id: number | null;
}

const UsersSubteamAddModal = () => {
  const { team, division, divisions } = useSelector((state: RootState) => state.profile);
  const { parentTeamMembers, subteamMembers, activeDivision, isUsersSubteamAddModalOpen, membersQuery } = useSelector(
    (state: RootState) => state.teamMembersPage
  );

  const [allMembers, setAllMembers] = useState<TeamMember[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<TeamMember[]>([]);
  const [membersSearchValue, setMembersSearchValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUsersSubteamAddModalOpen && team.id && division.id) {
      dispatch(getParentTeamMembersAction({ team, division }));
    }
  }, [isUsersSubteamAddModalOpen, team.id, division.id]);

  useEffect(() => {
    setAllMembers(parentTeamMembers.data);
  }, [parentTeamMembers.data]);

  const handleSelectedMembersReset = () => {
    setSelectedMembers([]);
  };

  const handleOnConfirm = () => {
    const formattedSubteamMembers = [...subteamMembers.data, ...selectedMembers];
    dispatch(
      updateDivisionMembersAction({
        team,
        divisions,
        membersQuery,
        page: subteamMembers.page,
        pageSize: subteamMembers.pageSize,
        subteamMembers: formattedSubteamMembers,
        validSubteamMembers: formattedSubteamMembers,
        activeDivision,
        callback: handleSelectedMembersReset()
      })
    );
  };

  const handleOnClose = () => {
    handleSelectedMembersReset();
    dispatch(setTeamMembersAction({ isUsersSubteamAddModalOpen: false }));

    setTimeout(() => {
      dispatch(setTeamMembersAction({ selectedUser: null }));
    }, 150);
  };

  const onMemberSelect = (event: React.ChangeEvent, data: any) => {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      setSelectedMembers((prev: any) => [...prev, data]);
    } else {
      setSelectedMembers((prev: any) => prev.filter((selectedMember: any) => selectedMember.id !== data.id));
    }
  };

  const handleMembersSearchValueChange = (value: any) => {
    setMembersSearchValue(value);
  };

  const handleOnSearch = () => {
    const copiedAllMembers = Array.from(parentTeamMembers.data);
    const formattedAllMembers: any = copiedAllMembers.filter((member: any) =>
      member.username.toLowerCase().includes(membersSearchValue.toLowerCase())
    );
    setAllMembers(formattedAllMembers);
  };

  return (
    <Modal
      title={`Add member to ${activeDivision?.label}`}
      width="w-7/12 max-w-xl"
      bg="bg-ev-yellow-1"
      onClose={handleOnClose}
      isOpen={isUsersSubteamAddModalOpen}
    >
      <InputSearch
        value={membersSearchValue}
        onChange={handleMembersSearchValueChange}
        reset={() => {
          handleMembersSearchValueChange('');
          setAllMembers(parentTeamMembers.data);
        }}
        placeholder="Find members"
        label="Find members:"
        onClick={handleOnSearch}
      />
      <div className="overflow-y-auto mt-6 max-h-[296px] w-full flex flex-col gap-y-1">
        {allMembers.map((member: any) => (
          <div key={member.id} className="h-14 w-full p-4 flex items-center rounded bg-white">
            <CheckboxInput
              disabled={subteamMembers.data.some((innerMember: any) => innerMember.id === member.id)}
              onChange={(event) => onMemberSelect(event, member)}
              label={member.username}
              labelClass="ml-2 text-base font-normal cursor-default"
            />
          </div>
        ))}
      </div>
      <hr />
      <div className="mt-8 flex gap-4">
        <PrimaryButton onClick={handleOnConfirm} text="Confirm" />
        <PrimaryButton onClick={handleOnClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </Modal>
  );
};

export default UsersSubteamAddModal;
