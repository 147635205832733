import { EditorModeOptions, LocationMapCustomConfig, LocationMapResolution } from '@visual-elements/location-map';
import {
  LocationMapAggregatedOptions,
  LocationMapViewState
} from '../../../pages/Editor/reducers/locationMapConfigTypes';

export type LocationMapExportMappingOptions = {
  resolution: LocationMapResolution;
  disableControls?: boolean;
};

export function getLocationMapPropsForExporting(
  aggregatedOptions: LocationMapAggregatedOptions,
  exportMappingOptions: LocationMapExportMappingOptions
) {
  const mappingOptions: LocationMapMappingOptions = {
    disableText: false,
    editorMode: {},
    reuseMaps: false,
    disableControls: false,
    interactive: { override: false },
    viewState: { override: false },
    ...exportMappingOptions
  };
  return mapToLocationMapProps(aggregatedOptions, mappingOptions);
}

export type LocationMapMappingOptions = {
  viewState: { override: true; value: LocationMapViewState } | { override: false };
  resolution: LocationMapResolution;
  disableText: boolean;
  disableControls: boolean;
  editorMode: Partial<EditorModeOptions>;
  reuseMaps: boolean;
  interactive: { override: true; value: boolean } | { override: false };
};

export function mapToLocationMapProps(
  aggregatedOptions: LocationMapAggregatedOptions,
  mappingOptions: LocationMapMappingOptions
): LocationMapCustomConfig {
  const props: LocationMapCustomConfig = { ...aggregatedOptions, ...mappingOptions.editorMode };

  if (props.reuseMaps) {
    props.reuseMaps = mappingOptions.reuseMaps;
  }

  if (mappingOptions.viewState.override) {
    props.viewState = {
      ...mappingOptions.viewState.value,
      referenceHeight: aggregatedOptions.viewState?.referenceHeight,
      referenceWidth: aggregatedOptions.viewState?.referenceWidth,
      zoom: aggregatedOptions.viewState?.zoom
    };
  }

  if (mappingOptions.disableControls) {
    delete props.fullScreenControls;
    delete props.geolocateControls;
    delete props.mapNavigation;
  }
  if (mappingOptions.disableText) {
    delete props.title;
    delete props.subtitle;
    delete props.caption;
  }

  if (mappingOptions.interactive.override) {
    props.interactive = mappingOptions.interactive.value;
  }

  if (mappingOptions.resolution) {
    props.resolution = mappingOptions.resolution;
  }

  return props;
}
